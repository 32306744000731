<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <form class="form">
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Account security") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">{{
              $t("Update your account security")
            }}</span>
          </div>
        </div>
        <div class="card-body detail">
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-6">
              <h5 class="font-weight-bold mb-6">Password reset:</h5>
            </div>
          </div>

          <validation-provider v-slot="{ errors }" :name="$t('Old password')" vid="old_password" rules="required">
            <euro-input v-model="form.old_password" :label="`${$t('Old password')}*`" :error-messages="errors" horizontal
              type="password"></euro-input>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('New password')" vid="new_password" rules="required">
            <euro-input v-model="form.new_password" :label="`${$t('New password')}*`" :error-messages="errors" horizontal
              type="password"></euro-input>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('Confirm Password')" vid="confirm_password"
            rules="required|confirmed:new_password">
            <euro-input v-model="form.confirm_password" :label="`${$t('Confirm Password')}*`" :error-messages="errors"
              horizontal type="password"></euro-input>
          </validation-provider>

          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-6 text-right">
              <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
                @click.prevent="handleSubmit(save)">
                {{ $t("Save Changes") }}
              </button>
            </div>
          </div>

          <div class="separator separator-dashed my-10"></div>

          <physical-person-mfa-form></physical-person-mfa-form>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UserService from "@/core/services/users.service";
import { mapState } from "vuex";
import icons from "@/core/config/icons.js";
import { backendErrorSwal } from "@/core/helpers/swal";
import PhysicalPersonMfaForm from "@/view/components/forms/physical-person/PhysicalPersonMfaForm.vue";
import { successToast } from '@/core/helpers';

const DEFAULT_FORM = {
  new_password: "",
  old_password: "",
  confirm_password: "",
};
export default {
  components: {
    PhysicalPersonMfaForm,
  },

  data() {
    return {
      icons,
      apiErrors: null,
      form: { ...DEFAULT_FORM },
      isSaving: false,
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    requestBody() {
      return {
        new_password: this.form.new_password,
        old_password: this.form.old_password,
      };
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          new_password: [...(val.new_password ?? [])],
          old_password: [...(val.old_password ?? [])],
          confirm_password: [...(val.confirm_password ?? [])],
        };

        formObs.setErrors({ ...newErrors });
      },
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Account security") },
    ]);
  },

  methods: {
    async save() {
      this.isSaving = true;
      try {
        await UserService.changePassword(this.user.id, this.requestBody);
        this.$emit("updated");
        this.resetForm();
        successToast(this.$t("Password changed successfully"));
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err?.response?.data?.detail, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    cancel() {
      this.resetForm();
    },

    resetForm() {
      this.apiErrors = null;
      this.form = { ...DEFAULT_FORM };
    },
  },
};
</script>
