<template>
  <div>
    <div class="row">
      <label class="col-3"></label>
      <div class="col-9">
        <h5 class="font-weight-bold mb-6">{{ $t("Multi-factor authentication") }}:</h5>
      </div>
    </div>

    <div v-for="option in optionsList" :key="option.text" class="form-group row">
      <label class="col-3 text-capitalize">{{ option.text }}</label>
      <div class="col-9">
        <div class="d-flex gap-1 align-items-center">
          <template v-if="option.active">
            <span class="label label-md label-light-success label-inline label-rounded mr-4">{{ $t('Active')
            }}</span>
            <btn-icon size="xs" class=" mr-2" :icon="icons.undo" :tooltip="$t('Regenerate backup tokens')"
              @click="regenerate(option.value)"></btn-icon>
            <btn-icon :tooltip="$t('Remove MFA method')" :icon="icons.delete" @click="remove(option.value)">
            </btn-icon>
          </template>
          <template v-else>
            <template v-if="!option.active && activeMfaMethods.length > 0">
              <div>
                <span class="label label-md label-light-dark label-inline label-rounded mb-1">{{ $t('Disabled')
                }}</span>
                <div class="text-muted font-size-xs">{{ $t('Before enabling other methods, you must disable current active method') }}</div>
              </div>
            </template>
            <template v-else>
              <button class="btn btn-light-primary font-weight-bold btn-sm" @click.prevent="activate(option.value)">
                {{ $t("Activate") }}
              </button>
            </template>
          </template>
        </div>
      </div>
    </div>

    <!-- Form disattivazione metodi -->
    <b-card v-if="showRemove" class="my-5">
      <validation-observer ref="removedValidationObserver">
        <h6 class="font-weight-bolder">{{ $t("Confirm removal") }}</h6>
        <template v-if="selectedMethod === 'app'">
          <p>{{ $t('To disable this method you need to enter the code generated by the application you use to manage 2FA') }}</p>
          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the app') }}</h6>
        </template>
        <template v-else-if="selectedMethod === 'email'">
          <p>{{ $t('To disable this method you need to enter the code generated by email') }}</p>
          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the email') }}</h6>
        </template>
        <template v-else>
          <p>{{ $t('An SMS has been sent to you with a code, use it only for this procedure and do not give it to anyone') }}</p>
          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the sms') }}</h6>
        </template>
        <div class="col-3 mx-0 px-0">
          <validation-provider v-slot="{ errors }" name="code" vid="code" rules="required">
            <euro-input v-model="confirmationCode" placeholder="xxxxxx" :error-messages="errors"></euro-input>
          </validation-provider>
        </div>
      </validation-observer>

      <div class="separator separator-solid my-5"></div>

      <div class="text-right">
        <button class="btn btn-secondary mr-4" @click.prevent="showRemove = false">
          {{ $t("Cancel") }}
        </button>
        <b-button variant="success" @click.prevent="removeMfaMethod()">
          {{ $t("Confirm") }}
        </b-button>
      </div>
    </b-card>

    <!-- Form reset metodi -->
    <b-card v-if="showRegenerete" class="my-5">
      <validation-observer ref="regenereteValidationObserver">
        <h6 class="font-weight-bolder">{{ $t("Regenerate backup tokens") }}</h6>
        <p>{{ $t("These codes serve as alternative methods of access in case you are unable to access with the active authentication method") }}</p>
        <p>{{ $t("Please Enter the confirmation code we've sent you.") }}</p>
        <template v-if="selectedMethod === 'app'">
          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the app') }}</h6>
        </template>
        <template v-else-if="selectedMethod === 'email'">
          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the email') }}</h6>
        </template>
        <template v-else>
          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the sms') }}</h6>
        </template>
        <div class="col-3 mx-0 px-0">
          <validation-provider v-slot="{ errors }" name="code" vid="code" rules="required">
            <euro-input v-model="confirmationCode" placeholder="xxxxxx" :error-messages="errors"></euro-input>
          </validation-provider>
        </div>
      </validation-observer>

      <div class="separator separator-solid my-5"></div>

      <div class="text-right">
        <button class="btn btn-secondary mr-4" @click.prevent="showRegenerete = false">
          {{ $t("Cancel") }}
        </button>
        <b-button variant="success" @click.prevent="regenerateBackupCodes()">
          {{ $t("Confirm") }}
        </b-button>
      </div>
    </b-card>

    <!-- Form attivatione metodi -->
    <b-card v-if="showActivate" class="my-5">
      <validation-observer ref="confirmationValidationObserver">
        <template v-if="selectedMethod == 'app'">
          <h6 class="font-weight-bolder">{{ $t('Setup authenticator app') }}</h6>
          <p>Password managers like <a target="_blank"
              href="https://support.1password.com/one-time-passwords/">1Password</a>, <a target="_blank"
              href="https://authy.com/guides/github/">Authy</a>, <a target="_blank"
              href="https://www.microsoft.com/en-us/account/authenticator/">Microsoft Authenticator</a>, etc. have apps
            and browser extensions that you can use to get 2FA codes when prompted during sign-in.</p>

          <h6 class="font-weight-bolder mt-7">{{ $t('Scan the QR code') }}</h6>
          <p>{{ $t('Use an authenticator app or browser extension to scan. If you are unable to scan, instead. Password managers can use it to generate 2FA codes.') }}</p>

          <canvas ref="qrcode"></canvas>

          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the app') }}</h6>
          <div class="col-3 mx-0 px-0">
            <validation-provider v-slot="{ errors }" name="code" vid="code" rules="required">
              <euro-input v-model="confirmationCode" placeholder="xxxxxx" :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
        </template>
        <template v-else-if="selectedMethod == 'email'">
          <h6 class="font-weight-bolder">{{ $t('Setup authenticator email') }}</h6>
          <p>{{ $t('An email will be sent to you with the verification code') }}</p>

          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the email') }}</h6>
          <div class="col-3 mx-0 px-0">
            <validation-provider v-slot="{ errors }" name="code" vid="code" rules="required">
              <euro-input v-model="confirmationCode" placeholder="xxxxxx" :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
        </template>
        <template v-else>
          <h6 class="font-weight-bolder">{{ $t('Setup authenticator sms') }}</h6>
          <p>{{ $t('An SMS has been sent to you with a code, use it only for this procedure and do not give it to anyone') }}</p>

          <h6 class="font-weight-bolder mt-7">{{ $t('Verify the code from the sms') }}</h6>
          <div class="col-3 mx-0 px-0">
            <validation-provider v-slot="{ errors }" name="code" vid="code" rules="required">
              <euro-input v-model="confirmationCode" placeholder="xxxxxx" :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
        </template>
      </validation-observer>

      <div class="separator separator-solid my-5"></div>

      <div class="text-right">
        <button class="btn btn-secondary mr-4" @click="showActivate = false">
          {{ $t("Cancel") }}
        </button>
        <b-button variant="success" @click.prevent="confirmActivation()">
          {{ $t("Confirm") }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from "@/core/helpers";
import icons from "@/core/config/icons.js";
import AuthService from "@/core/services/auth.service";
import QRious from "qrious";
import Swal from "sweetalert2";

const MfaActionsEnum = Object.freeze({
  DELETE: "delete",
  REGENERATE: "regenerate",
  ACTIVATE: "activate",
  SET_PRIMARY: "set-primary",
});

export default {
  data() {
    return {
      icons,
      confirmationCode: "",
      action: false,
      selectedMethod: '',
      MfaActionsEnum,
      activeMfaMethods: [],
      optionsList: [],
      options: [
        { value: "app", text: "App" },
        { value: "email", text: "Email" },
        { value: "sms_api", text: "SMS" },
      ],
      showActivate: false,
      showRemove: false,
      showRegenerete: false,
    };
  },

  computed: {
    disabled() {
      return this.selectedMethod == ''
    },

    tooltipStatusButton(active) {
      if (active) {
        return ""
      }

      return "Activate"
    }
  },

  watch: {
  },

  async created() {
    await this.getActiveMfaMethods()
    this.refreshOptionList()
  },

  methods: {
    refreshOptionList() {
      const methods = this.activeMfaMethods.map(method => { return method.name })
      const optionList = this.options.map(option => {
        option.active = methods.includes(option.value)
        return option
      });

      this.optionsList = optionList
    },

    methodNameHuman(method) {
      switch (method) {
        case "sms_api":
          return "SMS";

        default:
          return method;
      }
    },

    async getActiveMfaMethods() {
      const r = await AuthService.activeMfaMethods();
      this.activeMfaMethods = r.results;
    },

    reset() {
      this.showActivate = false
      this.showRemove = false
      this.showRegenerete = false
      this.action = false
      this.selectedMethod = ''
      this.confirmationCode = ''
    },

    remove(method) {
      this.reset()
      this.action = MfaActionsEnum.DELETE
      this.selectedMethod = method
      this.showRemove = true

      this.requestNewMfaCode()
    },

    regenerate(method) {
      this.reset()
      this.action = MfaActionsEnum.REGENERATE
      this.selectedMethod = method
      this.showRegenerete = true

      this.requestNewMfaCode()
    },

    async activate(method) {
      this.reset()
      this.action = MfaActionsEnum.ACTIVATE
      this.selectedMethod = method
      this.showActivate = true

      try {
        const r = await AuthService.activateMfa(this.selectedMethod);

        if (this.selectedMethod == "email" || this.selectedMethod == "sms_api") {
          successToast(r.details);
        }

        if (this.selectedMethod == "app") {
          await this.$nextTick();
          new QRious({
            element: this.$refs.qrcode,
            value: r.details,
            size: 200,
          });
        }

      } catch (err) {
        this.showActivate = false;
        backendErrorSwal(err);
      }
    },

    showBackupCodesSwal(backupCodes) {
      Swal.fire({
        title: this.$t("Backup tokens"),
        html: `
          <p class="font-weight-bold">${this.$t("These are your backup codes. Store them somewhere safe, you won't be able to see this popup again!")}</p>
          <ul>
            ${backupCodes
            .map(code => {
              return `<li>
                ${code}
              </li>`;
            })
            .join(" ")}
          </ul>
        `,
      });
    },

    async requestNewMfaCode() {
      if (!this.selectedMethod || this.selectedMethod === "app") {
        return;
      }

      const r = await AuthService.requestNewMfaCode(this.selectedMethod);

      Swal.fire({
        title: this.$t("Confirmation code sent!"),
        text: r?.detail ?? this.$t("A confirmation code has been sent!"),
      });
    },

    async confirmActivation() {
      try {
        const r = await AuthService.confirmMfaActivation(this.selectedMethod, this.confirmationCode);
        successToast(this.$t("Multi factor authentication enabled"));

        // Ricarcio lista metodi attivi
        await this.getActiveMfaMethods()
        // Aggiorno elenco
        this.refreshOptionList()

        this.showBackupCodesSwal(r.backup_codes);

        this.reset();
      } catch (err) {
        if (typeof err == "object") {
          this.$refs.confirmationValidationObserver.setErrors(err);
          return;
        }

        backendErrorSwal(err);
      }
    },

    async removeMfaMethod() {
      try {
        await AuthService.deactivateMfaMethod(this.selectedMethod, this.confirmationCode);
        successToast(this.$t("Multi factor authentication removed"));

        // Ricarcio lista metodi attivi
        await this.getActiveMfaMethods()
        // Aggiorno elenco
        this.refreshOptionList()

        this.reset();
      } catch (err) {
        if (typeof err == "object") {
          this.$refs.removedValidationObserver.setErrors(err);
          return;
        }

        backendErrorSwal(err);
      }
    },

    async regenerateBackupCodes() {
      try {
        const res = await AuthService.regenerateBackupCodes(this.selectedMethod, this.confirmationCode);
        this.showBackupCodesSwal(res.backup_codes);

        // Ricarcio lista metodi attivi
        await this.getActiveMfaMethods()
        // Aggiorno elenco
        this.refreshOptionList()

        this.reset();
      } catch (err) {
        if (typeof err == "object") {
          this.$refs.regenereteValidationObserver.setErrors(err);
          return;
        }

        backendErrorSwal(err);
      }
    },
  },
};
</script>

