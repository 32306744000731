var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('label', {
    staticClass: "col-3"
  }), _c('div', {
    staticClass: "col-9"
  }, [_c('h5', {
    staticClass: "font-weight-bold mb-6"
  }, [_vm._v(_vm._s(_vm.$t("Multi-factor authentication")) + ":")])])]), _vm._l(_vm.optionsList, function (option) {
    return _c('div', {
      key: option.text,
      staticClass: "form-group row"
    }, [_c('label', {
      staticClass: "col-3 text-capitalize"
    }, [_vm._v(_vm._s(option.text))]), _c('div', {
      staticClass: "col-9"
    }, [_c('div', {
      staticClass: "d-flex gap-1 align-items-center"
    }, [option.active ? [_c('span', {
      staticClass: "label label-md label-light-success label-inline label-rounded mr-4"
    }, [_vm._v(_vm._s(_vm.$t('Active')))]), _c('btn-icon', {
      staticClass: "mr-2",
      attrs: {
        "size": "xs",
        "icon": _vm.icons.undo,
        "tooltip": _vm.$t('Regenerate backup tokens')
      },
      on: {
        "click": function click($event) {
          return _vm.regenerate(option.value);
        }
      }
    }), _c('btn-icon', {
      attrs: {
        "tooltip": _vm.$t('Remove MFA method'),
        "icon": _vm.icons.delete
      },
      on: {
        "click": function click($event) {
          return _vm.remove(option.value);
        }
      }
    })] : [!option.active && _vm.activeMfaMethods.length > 0 ? [_c('div', [_c('span', {
      staticClass: "label label-md label-light-dark label-inline label-rounded mb-1"
    }, [_vm._v(_vm._s(_vm.$t('Disabled')))]), _c('div', {
      staticClass: "text-muted font-size-xs"
    }, [_vm._v(_vm._s(_vm.$t('Before enabling other methods, you must disable current active method')))])])] : [_c('button', {
      staticClass: "btn btn-light-primary font-weight-bold btn-sm",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.activate(option.value);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Activate")) + " ")])]]], 2)])]);
  }), _vm.showRemove ? _c('b-card', {
    staticClass: "my-5"
  }, [_c('validation-observer', {
    ref: "removedValidationObserver"
  }, [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Confirm removal")))]), _vm.selectedMethod === 'app' ? [_c('p', [_vm._v(_vm._s(_vm.$t('To disable this method you need to enter the code generated by the application you use to manage 2FA')))]), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the app')))])] : _vm.selectedMethod === 'email' ? [_c('p', [_vm._v(_vm._s(_vm.$t('To disable this method you need to enter the code generated by email')))]), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the email')))])] : [_c('p', [_vm._v(_vm._s(_vm.$t('An SMS has been sent to you with a code, use it only for this procedure and do not give it to anyone')))]), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the sms')))])], _c('div', {
    staticClass: "col-3 mx-0 px-0"
  }, [_c('validation-provider', {
    attrs: {
      "name": "code",
      "vid": "code",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-input', {
          attrs: {
            "placeholder": "xxxxxx",
            "error-messages": errors
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })];
      }
    }], null, false, 2078822501)
  })], 1)], 2), _c('div', {
    staticClass: "separator separator-solid my-5"
  }), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary mr-4",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showRemove = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.removeMfaMethod();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Confirm")) + " ")])], 1)], 1) : _vm._e(), _vm.showRegenerete ? _c('b-card', {
    staticClass: "my-5"
  }, [_c('validation-observer', {
    ref: "regenereteValidationObserver"
  }, [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Regenerate backup tokens")))]), _c('p', [_vm._v(_vm._s(_vm.$t("These codes serve as alternative methods of access in case you are unable to access with the active authentication method")))]), _c('p', [_vm._v(_vm._s(_vm.$t("Please Enter the confirmation code we've sent you.")))]), _vm.selectedMethod === 'app' ? [_c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the app')))])] : _vm.selectedMethod === 'email' ? [_c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the email')))])] : [_c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the sms')))])], _c('div', {
    staticClass: "col-3 mx-0 px-0"
  }, [_c('validation-provider', {
    attrs: {
      "name": "code",
      "vid": "code",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "placeholder": "xxxxxx",
            "error-messages": errors
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })];
      }
    }], null, false, 2078822501)
  })], 1)], 2), _c('div', {
    staticClass: "separator separator-solid my-5"
  }), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary mr-4",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showRegenerete = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.regenerateBackupCodes();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Confirm")) + " ")])], 1)], 1) : _vm._e(), _vm.showActivate ? _c('b-card', {
    staticClass: "my-5"
  }, [_c('validation-observer', {
    ref: "confirmationValidationObserver"
  }, [_vm.selectedMethod == 'app' ? [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('Setup authenticator app')))]), _c('p', [_vm._v("Password managers like "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://support.1password.com/one-time-passwords/"
    }
  }, [_vm._v("1Password")]), _vm._v(", "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://authy.com/guides/github/"
    }
  }, [_vm._v("Authy")]), _vm._v(", "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.microsoft.com/en-us/account/authenticator/"
    }
  }, [_vm._v("Microsoft Authenticator")]), _vm._v(", etc. have apps and browser extensions that you can use to get 2FA codes when prompted during sign-in.")]), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Scan the QR code')))]), _c('p', [_vm._v(_vm._s(_vm.$t('Use an authenticator app or browser extension to scan. If you are unable to scan, instead. Password managers can use it to generate 2FA codes.')))]), _c('canvas', {
    ref: "qrcode"
  }), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the app')))]), _c('div', {
    staticClass: "col-3 mx-0 px-0"
  }, [_c('validation-provider', {
    attrs: {
      "name": "code",
      "vid": "code",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "placeholder": "xxxxxx",
            "error-messages": errors
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })];
      }
    }], null, false, 2078822501)
  })], 1)] : _vm.selectedMethod == 'email' ? [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('Setup authenticator email')))]), _c('p', [_vm._v(_vm._s(_vm.$t('An email will be sent to you with the verification code')))]), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the email')))]), _c('div', {
    staticClass: "col-3 mx-0 px-0"
  }, [_c('validation-provider', {
    attrs: {
      "name": "code",
      "vid": "code",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "placeholder": "xxxxxx",
            "error-messages": errors
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })];
      }
    }], null, false, 2078822501)
  })], 1)] : [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('Setup authenticator sms')))]), _c('p', [_vm._v(_vm._s(_vm.$t('An SMS has been sent to you with a code, use it only for this procedure and do not give it to anyone')))]), _c('h6', {
    staticClass: "font-weight-bolder mt-7"
  }, [_vm._v(_vm._s(_vm.$t('Verify the code from the sms')))]), _c('div', {
    staticClass: "col-3 mx-0 px-0"
  }, [_c('validation-provider', {
    attrs: {
      "name": "code",
      "vid": "code",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-input', {
          attrs: {
            "placeholder": "xxxxxx",
            "error-messages": errors
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })];
      }
    }], null, false, 2078822501)
  })], 1)]], 2), _c('div', {
    staticClass: "separator separator-solid my-5"
  }), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary mr-4",
    on: {
      "click": function click($event) {
        _vm.showActivate = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmActivation();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Confirm")) + " ")])], 1)], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }