var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Account security")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(_vm._s(_vm.$t("Update your account security")))])])]), _c('div', {
          staticClass: "card-body detail"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('label', {
          staticClass: "col-xl-3"
        }), _c('div', {
          staticClass: "col-lg-9 col-xl-6"
        }, [_c('h5', {
          staticClass: "font-weight-bold mb-6"
        }, [_vm._v("Password reset:")])])]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Old password'),
            "vid": "old_password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Old password'), "*"),
                  "error-messages": errors,
                  "horizontal": "",
                  "type": "password"
                },
                model: {
                  value: _vm.form.old_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "old_password", $$v);
                  },
                  expression: "form.old_password"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('New password'),
            "vid": "new_password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('New password'), "*"),
                  "error-messages": errors,
                  "horizontal": "",
                  "type": "password"
                },
                model: {
                  value: _vm.form.new_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "new_password", $$v);
                  },
                  expression: "form.new_password"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Confirm Password'),
            "vid": "confirm_password",
            "rules": "required|confirmed:new_password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Confirm Password'), "*"),
                  "error-messages": errors,
                  "horizontal": "",
                  "type": "password"
                },
                model: {
                  value: _vm.form.confirm_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "confirm_password", $$v);
                  },
                  expression: "form.confirm_password"
                }
              })];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "row"
        }, [_c('label', {
          staticClass: "col-xl-3"
        }), _c('div', {
          staticClass: "col-lg-9 col-xl-6 text-right"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "separator separator-dashed my-10"
        }), _c('physical-person-mfa-form')], 1)])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }